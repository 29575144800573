(function($) {
  const togglebutton = () => {
    $('.toggle-button').each((i, e) => {
      if (($(e).data('toggle-viz') == true) || ($(e).data('toggle-slide') == true)) {
        const $button = $(e);
        const $toggle = $($button.data('toggle'));
        const original_label = $button.find('[class*="__label"]').text();
        const original_icon = $button.find('.ic').attr('class').match(/ic--(\S+)/)[1];
        $button.data('toggle-icon', original_icon);
        $button.data('toggle-label', original_label);
        if ($toggle.prop('hidden')) {
          $toggle.attr('aria-hidden', true);
        }
      }
    });

    $('[data-toggle]').on('click', (e) => {
      const $button = $(e.currentTarget);
      const toggleclass = $button.data('toggle-class');
      const toggleviz = ($button.data('toggle-viz') == true);
      const toggleslide = ($button.data('toggle-slide') == true);
      const $toggle = $($button.data('toggle'));
      const pressed = ($button.attr('aria-expanded') == 'true');
      const toggled_label = $button.data('toggle-toggled-label');
      const toggled_icon = $button.data('toggle-toggled-icon');
      const original_label = $button.data('toggle-label');
      const original_icon = $button.data('toggle-icon');
      const button_toggled_class = $button.data('toggle-button-toggled-class');
      const focus = $button.data('toggle-focus');
      const newpressed = (pressed) ? 'false' : 'true';
      const $parent_toggled_item = $button.parents($button.data('toggle-parent-item'));
      const parent_toggled_class = $button.data('toggle-parent-class');

      // swap the label if a toggled label is specified, ie "close menu"
      if (toggled_label) {
        const nextLabel = (newpressed == 'true') ? toggled_label : original_label;
        $button.find('[class*="__label"]').text(nextLabel);
      }
      // swap the icon if a toggled icon is spedified, for example, a close icon
      if (toggled_icon) {
        const nextIcon = (newpressed == 'true') ? toggled_icon : original_icon;
        const removeIcon = (newpressed == 'true') ? original_icon : toggled_icon;
        $button.find('.ic').addClass('ic--' + nextIcon).removeClass('ic--' + removeIcon);
      }
      // option to toggle a class on the toggled item itself
      if (toggleclass) {
        $toggle.toggleClass(toggleclass, newpressed);
      }
      // option to toggle a class on the toggle button
      if (button_toggled_class) {
        $button.toggleClass(button_toggled_class, newpressed);
      }
      // option to toggle a class to a specified parent of the toggle
      if (parent_toggled_class) {
        $parent_toggled_item.toggleClass(parent_toggled_class, newpressed);
      }
      // toggleviz = basic instant show/hide
      if (toggleviz) {
        const isHidden = $toggle.prop('hidden');
        $toggle.prop('hidden', !isHidden);
        $toggle.attr({
          'aria-hidden': !isHidden
        });
      }
      // toggleslide = toggle that animates sliding up/down
      if (toggleslide) {
        $toggle.slideToggle();
        const isHidden = $toggle.prop('hidden');
        $toggle.prop('hidden', !isHidden);
        $toggle.attr({
          'aria-hidden': !isHidden
        });
      }
      if (focus) {
        $(focus).focus();
      }
      $button.attr('aria-expanded', newpressed);
    });
  };

  togglebutton();
})(jQuery);